import React, {Dispatch, FC, SetStateAction, useMemo} from 'react';
import { ProgressBar } from 'src/components/common/ProgressBar';
import { Button } from 'src/components/common';
import { getDeviceType } from 'src/utils/inspectDeviceType';
import { QuestionnaireStatuses, QuestionnairesTypes } from 'src/constans/constans';
import { FamilyTreeSettingsInterface } from 'src/models/RiskAssessmentQuestionnaire';

import {
  ButtonContainer,
  GeneralInfoContainer,
  GeneralInfoTitle
} from './QuestionnaireGeneralInfo.styles'

interface Props {
  title: string
  onClickHandler: (questionnaireName: QuestionnairesTypes) => void
  completedAssessmentHandler?: () => void
  answeredBlocks: number
  totalBlocks: number
  hasFamilyTree?: boolean
  questionnaireStatus?: string
  questionnaireType: string
  isShowRAMainButton?: boolean
  editMyAnswersHandler?: () => void
  isEditMyAnswersButtonDisable?: boolean
  familyTreeSettings: FamilyTreeSettingsInterface
  setFamilyTreeSettings: Dispatch<SetStateAction<FamilyTreeSettingsInterface>>
}

const QuestionnaireGeneralInfo: FC<Props> = ({
  title,
  onClickHandler,
  completedAssessmentHandler,
  answeredBlocks,
  totalBlocks,
  questionnaireStatus,
  questionnaireType,
  isShowRAMainButton,
  editMyAnswersHandler,
  isEditMyAnswersButtonDisable,
  familyTreeSettings,
  setFamilyTreeSettings
}) => {
  const isMobile = getDeviceType() === 'mobile'
  const hasCompletedAssessmentButton = questionnaireStatus === QuestionnaireStatuses.COMPLETED
  const isRiskAssessmentQuestionnaire = questionnaireType === QuestionnairesTypes.RISK_ASSESSMENT
  const hasDisabledStatus = questionnaireStatus === QuestionnaireStatuses.BLOCKED
  const hasEditAnswers = hasCompletedAssessmentButton && !isRiskAssessmentQuestionnaire
  const hasMainButton = isRiskAssessmentQuestionnaire ? isShowRAMainButton : true

  const onClickHandler2 = () => {
    setFamilyTreeSettings((prevState) => {
      return {
        ...prevState,
        isOpen: true
      }
    })
  }
  const hasDisabledButton = useMemo(() => {
    return questionnaireStatus === QuestionnaireStatuses.BLOCKED
      || (!isRiskAssessmentQuestionnaire && familyTreeSettings.isLoading)
  }, [familyTreeSettings.isLoading, isRiskAssessmentQuestionnaire, questionnaireStatus])

  const buttonTitle = useMemo(() => {
    let title: string
    switch (questionnaireStatus) {
      case QuestionnaireStatuses.IN_PROGRESS:
        title = 'Continue'
        break
      case QuestionnaireStatuses.COMPLETED:
        title = isRiskAssessmentQuestionnaire ? 'View my results' : familyTreeSettings.isLoading ? 'Downloading Family Tree' : 'View my Cancer Family History'
        break
      default:
        title = 'Start'
    }

    return title
  }, [familyTreeSettings.isLoading, isRiskAssessmentQuestionnaire, questionnaireStatus])

  return (
    <GeneralInfoContainer
      isMobile={isMobile}
      hasDisabledStatus={hasDisabledStatus}
    >
      <GeneralInfoTitle hasDisabledStatus={hasDisabledStatus}>{title}</GeneralInfoTitle>
      <ProgressBar
        isMobile={isMobile}
        answeredBlocks={answeredBlocks}
        totalBlocks={totalBlocks}
        hasDisabledStatus={hasDisabledStatus}
        isGeneralStatus={true}
      />
      <ButtonContainer>
        {hasMainButton && <Button
          onClick={onClickHandler}
          disable={hasDisabledButton}
        >
          {buttonTitle}
        </Button>}
        { hasCompletedAssessmentButton && <Button onClick={completedAssessmentHandler} className='ghost-button'>View completed assessment</Button>}
        { hasEditAnswers && <Button
          onClick={editMyAnswersHandler}
          className='ghost-button'
          disable={isEditMyAnswersButtonDisable}
        >
          Edit my Answers
        </Button>}
        <Button
          key={1}
          className='submitButton'
          onClick={() => onClickHandler2()}
          disable={false}
        >
          test button
        </Button>
      </ButtonContainer>
    </GeneralInfoContainer>
  )
}

export default QuestionnaireGeneralInfo
